import PersonIcon from '@mui/icons-material/Person';
import { Alert, Avatar, Box, Button, Checkbox, Container, Grid, Link, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinnerCentred from "../Components/LoadingSpinnerCentred";
import AvailableProducts from "../Payment/AvailableProducts";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { ToChunks } from "../Utils/HelperFunc";

export default function Register(params)
{
    const { state } = useLocation();
    const [alertInfo, setAlertInfo] = useState(state?.alertInfo);
    const [success, setSuccess] = useState(false);
    const [nhsEmail, setNhsEmail] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [productId, setProductId] = useState(null);
    const [products, setProducts] = useState(null);
    const [tosAccepted, setTosAccepted] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get("email");
    let navigate = useNavigate();

    // Require that the tos are accepted and a product has been chosen to proceed
    const signUpButtonDisabled = !tosAccepted || productId === null;

    useEffect(() =>
    {
        axios.get('api/payments/products').then(result =>
        {
            setProducts(result.data);
        }).catch(error =>
        {
            setAlertInfo({ type: "error", message: "Failed to acquire available subscription information" });
        });
    }, []);

    function handleSubmit(event)
    {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const registerDto = 
        { 
            Name: data.get('name'),
            Email: data.get('email'),
            Password: data.get('password'),
            ProductId: productId
        };

        if (emailParam === null && productId === null)
        {
            setAlertInfo({ type: "warning", message: "Please select a product" });
            return;
        }

        setSubmitting(true);
        axios.post('api/users/register', registerDto).then(response =>
        {
            setSuccess(true);
            setNhsEmail(registerDto.Email.toLowerCase().endsWith("@nhs.net"));
        })
        .catch((error) =>
        {
            // TODO - need to check for incorrect email/pass or other error
            setSubmitting(false);
            setAlertInfo({ type: "error", message: error?.response?.data ?? "An error occured" });
        });
    }

    if (success)
    {
        if (nhsEmail)
        {
            return (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 8 }}>
                    <Alert severity="success">Success!</Alert>
                    <Button onClick={() => navigate("/login")} variant="contained" sx={{ mb: 2 }}>
                        Login
                    </Button>
                </Box>
            );
        }

        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
                <Alert severity="success">Success! A verification link has been sent to your email address.</Alert>
            </Box>
        );
    }

    if (submitting)
        return <LoadingSpinnerCentred topMargin={true} />

    if (products === null)
        return <LoadingSpinnerCentred topMargin={true} />;

    const userProducts = products.filter(p => !p.isInstitutionPackage);
    const institutionProducts = products.filter(p => p.isInstitutionPackage);
    const chunkedUserProducts = ToChunks(userProducts, 2);
    const chunkedInProducts = ToChunks(institutionProducts, 2);

    return (
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <PersonIcon />
            </Avatar>

            <Typography component="h1" variant="h5">Sign up</Typography>

            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>

                <Container maxWidth="xs">
                    {alertInfo && <Alert severity={alertInfo.type} sx={{ mb: 2 }}> {alertInfo.message}</Alert>}

                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            <TextField required fullWidth id="name" label="Name" name="name" autoComplete="name" autoFocus />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required fullWidth id="email" label="Email Address" name="email" autoComplete="email" defaultValue={emailParam} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required fullWidth id="password" label="Password" name="password" type="password" autoComplete="new-password" />
                        </Grid>
                    </Grid>
                </Container>

                {emailParam === null &&
                    <Box>
                        <Box sx={{ flexGrow: 2, display: { xs: 'flex', md: 'none' } }}>
                            <Container maxWidth="xs">
                                <Typography component="h2" variant="h6" textAlign="center" sx={{ mt: 2, mb: 1 }}>Subscription</Typography>

                                <AvailableProducts orientation="vertical" products={userProducts} productId={productId} setProductId={setProductId} includeFree={true} />

                                <Accordion sx={{ mt: 2 }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Typography textAlign="center" color="primary" component="h1" variant="h6">
                                            Institution Subscriptions
                                        </Typography>
                                    </AccordionSummary>

                                    <Typography component="h6" variant="subtitle" sx={{ ml: 2, mb: 2 }}>12 Month subscriptions with auto-renewal</Typography>
                                    <AvailableProducts orientation="vertical" productId={productId} setProductId={setProductId} products={institutionProducts} />
                                    <Typography component="h6" variant="subtitle" sx={{ m: 2 }}>Need a different package? Contact us at info@pathquestionbank.com with your requirements.</Typography>
                                </Accordion>
                            </Container>
                        </Box>

                        <Box sx={{ flexGrow: 2, display: { xs: 'none', md: 'flex' } }}>
                            <Container maxWidth="md">
                                <Typography component="h2" variant="h6" textAlign="center" sx={{ mt: 2, mb: 1 }}>Subscription</Typography>

                                <AvailableProducts productId={productId} setProductId={setProductId} produucts={null} includeFree={true} />
                                {chunkedUserProducts.map(chunk => (
                                    <AvailableProducts productId={productId} setProductId={setProductId} products={chunk} />
                                ))}

                                <Accordion sx={{ mt: 2 }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Typography textAlign="center" color="primary" component="h1" variant="h6">
                                            Institution Subscriptions
                                        </Typography>
                                    </AccordionSummary>
                                    
                                    <Typography component="h6" variant="subtitle" sx={{ ml: 2, mb: 2 }}>12 Month subscriptions with auto-renewal</Typography>
                                    {chunkedInProducts.map(chunk => (
                                        <AvailableProducts productId={productId} setProductId={setProductId} products={chunk} />
                                    ))}
                                    <Typography component="h6" variant="subtitle" sx={{ m: 2 }}>Need a different package? Contact us at info@pathquestionbank.com with your requirements.</Typography>
                                </Accordion>
                            </Container>
                        </Box>
                    </Box>
                }

                <Container maxWidth="xs">

                    <Box justifyContent="center" sx={{ display: 'flex', mt: 2 }} >
                        <Typography sx={{ padding: 0, mr: 1 }} component={Checkbox} inline onChange={(e) => setTosAccepted(e.target.checked)} />
                        <Typography sx={{ mr: .5 }} inline>{"I agree to the"}</Typography>
                        <Link sx={{ mr: .5 }} inline component="button" onClick={() => navigate("/tos")}>{"terms of service"}</Link>
                        <Typography sx={{ mr: .5 }} inline>and</Typography>
                        <Link inline component="button" onClick={() => navigate("/privacy-policy")}>privacy policy</Link>
                    </Box>

                    <Button disabled={signUpButtonDisabled} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        Sign Up
                    </Button>

                    <Grid container justifyContent="flex-end" sx={{ mb: 4 }}>
                        <Grid item>
                            <Link onClick={() => navigate("/login")} component="button" variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}